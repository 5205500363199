import { Message, MessageTypes, UUID } from '../share/messages';
import { uuid } from './crypthography';

interface PromiseExecutor<T> {
    resolve: (value?: T | PromiseLike<T>) => void;
    reject: (reason?: any) => void;
}

export class WebsocketController {
    private websocket: Promise<WebSocket>;
    private readonly messagesAwaitingReply = new Map<UUID, PromiseExecutor<Message>>();
    private ws: any;

    constructor(private readonly token: string,
                private readonly messagesCallback: (messages: Message) => void,
                // private readonly onErrorCallback: () => void,
                private readonly onCloseCallback: (messages: any) => void,
                private readonly onOpenCallback: (messages: any) => void) {
        console.log('connect');
        console.log(token);
        this.websocket = this.connect(token);
        console.log('Connected constructor');
        // console.log(this.websocket)
    }

    public reconnect(token:string){
        this.websocket = this.connect(token);
    }
    private get url(): string {
        const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
        const hostname = window.location.hostname;
        const host = window.location.host;
        console.log('frontend controller url')
        console.log(`${protocol}://${hostname}?token=`);
        return `wss://api.r0bot.cz:3001/?token=`;
        // return `ws://${hostname}:3001?token=`;
        // return 'ws://localhost:3001?token=';
        // return 'wss://192.168.1.19:3001?token=aaaaaaaa' //TODO
    }

    private connect(token:string): Promise<WebSocket> {
        return new Promise((resolve, reject) => {
            this.ws = new WebSocket(this.url + token);
            this.ws.addEventListener('open', () => {
                this.onOpenCallback(null);
                resolve(this.ws);
            });
            this.ws.addEventListener('error', (err: any) => {
                // this.onErrorCallback(err) ;
                console.log(err);
                reject(err);
            });
            this.ws.addEventListener('message', this.onMessageReceived);
            this.ws.addEventListener('close', this.onCloseCallback)
        });
    }

    private readonly onMessageReceived = (event: MessageEvent) => {
        const message = JSON.parse(event.data) as Message;
        console.log('onMessageReceived');
        console.log(message);
        if (this.messagesAwaitingReply.has(message.correlationId)) {
            // @ts-ignore
            this.messagesAwaitingReply.get(message.correlationId).resolve(message);
            this.messagesAwaitingReply.delete(message.correlationId);
        } else {
            this.messagesCallback(message); // an unexpected message from the server
        }
    }

    // private readonly onClose = (event:any) =>{
    //     console.log(event);
    // }
    //
    // private readonly onError = (event:any) => {
    //     console.log(event);
    // }
    async send(message: Partial<Message>, awaitForReply: boolean = false): Promise<Message> {
        console.log('sending in progress');
        return new Promise<Message>(async (resolve, reject) => {
            if (awaitForReply) {
                // @ts-ignore
                this.messagesAwaitingReply.set(message.correlationId ?? '0', { resolve, reject });
            }
            console.log('3-send');
            // console.log(this.websocket);
            this.websocket.then(
                ws => { ws.send(JSON.stringify(message));
                    console.log('in progresssss');},
                () => { this.messagesAwaitingReply.delete(message.correlationId ?? '0');
                    console.log('errrrrr');}
            );
        });
    }

    disconnect(): void {
         this.ws.disconnect && this.ws.disconnect();
    }

    async requestMessage(): Promise<{message:string}[]> {
        const reply = await this.send({
            type: MessageTypes.GetLongestChainRequest,
            correlationId: uuid()
        }, true);
        return reply.payload;
    }
    // async requestLongestChain(): Promise<Block[]> {
    //     const reply = await this.send({
    //         type: MessageTypes.GetLongestChainRequest,
    //         correlationId: uuid()
    //     }, true);
    //     return reply.payload;
    // }
    //
    // requestNewBlock(transactions: Transaction[]): void {
    //     this.send({
    //         type: MessageTypes.NewBlockRequest,
    //         correlationId: uuid(),
    //         payload: transactions
    //     });
    // }
    //
    // announceNewBlock(block: Block): void {
    //     this.send({
    //         type: MessageTypes.NewBlockAnnouncement,
    //         correlationId: uuid(),
    //         payload: block
    //     });
    // }
}