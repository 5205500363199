import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {SocketClient} from "./Socket";
import {WebsocketController} from "./lib/websocket-controller";
import { Message, MessageTypes, UUID } from './share/messages';
import {uuid} from "./lib/crypthography";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate
} from "react-router-dom";
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import {Security, SecureRoute, LoginCallback, useOktaAuth} from '@okta/okta-react';
import CustomLoginComponent from './Login';
import config from "./config";
import Home from "./Home";
import {ok} from "assert";
import {LangEdit} from "./lang/LangEdit";
import {LangRun} from "./lang/LangRun";
import {CalcList} from "./calc/CalcList";
import {CalcStat} from "./calc/CalcStat";
import {CalcGraph} from "./calc/CalcGraph";
import {CalcRun} from "./calc/CalcRun";



const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const [token, setToken] = useState('?');
  const [status, setStatus] = useState('x');
  const [user, setUser] = useState('--missing--');
  const [input, setInput] = useState( '');
  const [input2, setInput2] = useState( '');
  const [inputReply, setInputReply] = useState( '');
  const [inputReply2, setInputReply2] = useState( '');
  const [inputBroadcast, setInputBroadcast] = useState( '');
  const [listResult, setListResult] = useState<any[]>([]);
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);
  const navigate = useNavigate();
  const oktaContext = useOktaAuth();

  const handleServerMessages = (message: Message) => {
    console.log('1 - handleServerMessages');
    // @ts-ignore
    switch (message.type) {
      case MessageTypes.UserConnected: setUser(message.payload.message); return "";
      case MessageTypes.UserDisconnected: setUser(message.payload.message); return "";
      case MessageTypes.Message1 : setInput(message.payload.message); return "user";
      case MessageTypes.Message1Reply: setInputReply(message.payload.message) ; return "reply..";
      case MessageTypes.Message2 : setInput2(message.payload.message); return "user";
      case MessageTypes.Message2Reply: setInputReply2(message.payload.message); console.log(message.payload.message) ; return "reply..";
      case MessageTypes.MessageWelcomeGuest: setUser(message.payload?.field1); return "";
      case MessageTypes.MessageWelcomeMember: setUser(message.payload?.field1); return "";
      case MessageTypes.MessageCalcListResponse: console.log(message?.payload?.field1); return "";
        // case MessageTypes.GetLongestChainRequest: return this.handleGetLongestChainRequest(message);
        // case MessageTypes.NewBlockRequest       : return this.handleNewBlockRequest(message);
        // case MessageTypes.NewBlockAnnouncement  : return this.handleNewBlockAnnouncement(message);
      default: {
        console.log(`Received message of unknown type: "${message.type}"`);
      }
    }
  }

  // const handleError = ()=>{
  //   // console.log(message);
  //   // console.log(reason);
  //   // setStatus("ERROR");
  // }

  const handleOpen = (message: any)=>{
    // console.log('2 - handleOpen');
    // console.log(message);
    setStatus("OPEN");
  }
  const handleClose = (message:any) => {
    console.log(message);
    if (message.wasClean) {
      console.log(`Connection closed cleanly, code=${message.code}, reason=${message.reason}`);
    } else {
      console.error('Connection died');
    }
    setStatus("CLOSE");
  }

  // const server =  new WebsocketController(token ,handleServerMessages, handleClose, handleOpen);

  console.log('rendering...');
  const [server, setServer] = useState(
      new WebsocketController(token, handleServerMessages, handleClose, handleOpen)
  );

  useEffect(() => {
    console.log('token changed');

    server.disconnect();

    // This will run whenever the `token` prop changes
    setServer(new WebsocketController(token, handleServerMessages, handleClose, handleOpen));
  }, [token]);

  const triggerLogin = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    navigate('/login');
  };

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true);
    }
  };

  const onAuthResume = async () => {
    navigate('/login');
  };



  useEffect(()=> {

    return () => {
      server.disconnect();
      console.log('disconnect!');
    }
  },[])

  useEffect(()=>{
    var ac = oktaAuth.getAccessToken();
    if (ac !== undefined){
      setToken(ac);
      console.log('token set!!!');
      console.log(ac);
    }
  },[oktaAuth.getAccessToken()])

  async function handleClick() {
    await server.send({
      type: MessageTypes.Message1,
      correlationId: uuid(),
      payload: {field1: input},
    }, true).then(r => {
      console.log("Replying 1 Received:");
      console.log(r);
      setInputReply(r.payload.field1);
    });
    console.log('message sent..');
  }

  async function handleClick2() {
    await server.send({
      type: MessageTypes.Message2,
      correlationId: uuid(),
      payload: {field1: input2},
    }, true).then(r  => {console.log("Replying 2 Received:");console.log(r);setInputReply2(r.payload.field1);});
    console.log('message sent..');
  }

  async function handleClickBroadcast(){
    await server.send({
      type: MessageTypes.Message2,
      correlationId: uuid(),
      payload: {field1: inputBroadcast},
    }, true).then(r  => {console.log("Replying 2 Received:");console.log(r);setInputReply2(r.payload.field1);});
    console.log('message sent..');
  }

  async function handleClickCalcAdd(){
    await server.send({
      type: MessageTypes.MessageCalcAdded,
      correlationId: uuid(),
      payload: {field1: inputBroadcast},
    }, true).then(r  => {console.log("Replying 2 Received:");console.log(r);setInputReply2(r.payload.field1);});
    console.log('message sent..');
  }
  function handleReconnect(){
    server.reconnect(token);
  }

  const SECOND_MS = 5000;

  useEffect(() => {

    if (status === 'CLOSE') {
      const interval = setInterval(() => {
        console.log('Logs every minute');
        server.reconnect(token);
      }, SECOND_MS);


      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.

    }
  }, [status])


  async function handleClickCalcList() {

      await server.send({
        type: MessageTypes.MessageCalcListRequest,
        correlationId: uuid(),
        payload: {field1: "", user: "testUser"},
      }, true).then(r  => {console.log("Replying Calc list:");console.log(r);setListResult(r.payload.listResult);});
      console.log('message sent..');

  }

  return (
      <Security
          oktaAuth={oktaAuth}
          onAuthRequired={customAuthHandler}
          restoreOriginalUri={restoreOriginalUri}
      >
    <div className="App">
      <header className="App-header">
        {/*<img src={logo} className="App-logo" alt="logo" />*/}
        {/*<SocketClient />*/}
        <p>
          <button data-dtname="Click calc list" onClick={() => {
            handleClickCalcList();
            navigate('/calc-list');
          }
          }>calc list</button>
          <button data-dtname="Click calc graph" onClick={() => navigate('/calc-graph')}>calc graph</button>
          <button data-dtname="Click calc stat" onClick={() => navigate('/calc-stat')}>calc stat</button>
          <button data-dtname="Click calc run" onClick={() => navigate('/calc-run')}>calc run</button>

          <button onClick={() => navigate('/lang-edit')}>lang edit</button>
          <button onClick={() => navigate('/lang-run')}>lang run</button>
        </p>
        <p>Status * {status} *</p>
        <p>User * {user} * <button onClick={triggerLogin}>Login</button></p>
        <p>Input * {input} *</p>
        <p>Reply * {inputReply} *</p>
        <p>Input2 * {input2} *</p>
        <p>Reply2 * {inputReply2} *</p>
        <p>Broadcasting * {inputBroadcast} *</p>
        <input type={'text'} id={'field1'} name={'field1'} onChange={(e)=> {setInput( e.target.value)}} />
        <button onClick={handleClick}>Do it</button>
        <input type={'text'} id={'field2'} name={'field2'} onChange={(e)=> {setInput2( e.target.value)}} />
        <button onClick={handleClick2}>Do it2</button>
        <input type={'text'} id={'fieldBroadcast'} name={'fieldBroadcast'} onChange={(e)=> {setInputBroadcast( e.target.value)}} />
        <button onClick={handleClickBroadcast}>Do broadcast</button>
        <br/>
        <button onClick={handleReconnect}>reconnect</button>
        <br/>
        <button onClick={handleClickCalcAdd}>CALC</button>
      </header>
    </div>


            <Routes>
            <Route path="/"  element={<Home/>} />
            <Route path="/login/callback" element={<LoginCallback onAuthResume={onAuthResume} />} />
            <Route path="/login" element={<CustomLoginComponent {...{ setCorsErrorModalOpen }} />} />
            <Route path="/vpcsignincallback" element={<LoginCallback onAuthResume={onAuthResume} />} />
            {/*<SecureRoute path="/messages" component={Messages} />*/}
            {/*<SecureRoute path="/profile" component={Profile} />*/}
              <Route path="/calc-list" element={<CalcList list={listResult} />}  />
              <Route path="/calc-stat" element={<CalcStat />}  />
              <Route path="/calc-graph" element={<CalcGraph />}  />
              <Route path="/calc-run" element={<CalcRun />}  />

              <Route path="/lang-run" element={<LangRun />} />
              <Route path="/lang-edit" element={<LangEdit />} />

            </Routes>


      </Security>
  );
}

export default App;
