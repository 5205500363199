
const oktaDomain = 'dev-40050682.okta.com';
const oktaAuthorizer = 'ausb2zlunow6c1vom5d7';
const oktaClientId = '0oaazt5xo4u1PxBlu5d7';
const oktaIssuerUrl = 'https://dev-40050682.okta.com/oauth2/ausb2zlunow6c1vom5d7';
const oktaDiscoveryUrl = 'https://dev-40050682.okta.com/oauth2/ausb2zlunow6c1vom5d7/.well-known/openid-configuration';
// const oktaRedirectUrl = 'com.okta.dev-40050682:/vpcsignincallback';
//const oktaRedirectUrl = 'https://localhost:3000/vpcsignincallback';
const oktaRedirectUrl = 'https://r0bot.cz/vpcsignincallback';

const oktaLogoutRedirectUrl = 'com.okta.dev-40050682:/vpcsignout';
export default {
    oidc: {
        clientId: oktaClientId,
        issuer: oktaIssuerUrl,
        redirectUri: oktaRedirectUrl,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        // disableHttpsCheck: true,
    }
}
