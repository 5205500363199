
export type UUID = string;
export interface Message {
    correlationId: UUID;
    type: string;
    payload?: any;
    status?: any;
}


export enum MessageTypes {
    GetLongestChainRequest = 'GET_LONGEST_CHAIN_REQUEST',
    GetLongestChainResponse = 'GET_LONGEST_CHAIN_RESPONSE',
    NewBlockRequest = 'NEW_BLOCK_REQUEST',
    NewBlockAnnouncement = 'NEW_BLOCK_ANNOUNCEMENT',
    UserConnected = "UserConnected",
    UserDisconnected = "UserDisconnected",
    Message1 = "Message1",
    Message1Reply = "Reply1",
    Message2 = "Message2",
    Message2Reply = "Reply2",
    MessageBroadcast = "MessageBroadcast",
    MessageWelcomeGuest = "WelcomeGuest",
    MessageWelcomeMember = "WelcomeMember",
    MessageCalcAdded = "MessageCalcAdded",
    MessageCalcConfirm = "MessageCalcConfirm",
    MessageCalcListResponse = "MessageCalcListResponse",
    MessageCalcListRequest = "MessageCalcListRequest",
}