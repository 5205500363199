import React, {useEffect, useState} from 'react';

export const CalcList = ({list}:{list:any[]}) => {
    console.log(list);

    useEffect(() => {
        console.log('effect');
    }, [list]);

    return(
        <>
        <p>calc list</p>
            <table>
                <th><td>author</td><td>correct</td><td>incorrect</td><td>createdon</td></th>
            {
                list && list.map(c=>(
                    <tr><td>{c.author}</td><td>{c.correct}</td><td>{c.incorrect}</td><td>{c.createdOn}</td></tr>
                ))
            }
            </table>
        </>
    );
}